import React from "react"
import tw, { styled } from "twin.macro"

import Layout from '../components/Layout'
import {Box} from '../components/style'


const Text = tw.span`text-center text-base text-gray-700 mb-2`
const Email = tw.span`text-center text-lg text-gray-700 font-bold mb-2`
const Secondary = tw.span`text-center text-sm text-gray-500 mt-2`

export default function MessagePage({location}) {
  // console.log({location})
  return (
    <Layout style={{ paddingTop: 150}}>
      <Box>
        <Text>انت على بعد خطوة واحدة من المستقبل! رجاءً تحقق من بريدك الالكتروني</Text>
        <Email>{location && location.state && location.state.email}</Email>
        <Text>تم ارسال رسالة للتحقق من انه بريدك الالكتروني الخاص بك</Text>
        <Secondary>تاكد من مجلد الرسائل الغير مرغوب فيها</Secondary>
      </Box>
    </Layout>
  )
}
